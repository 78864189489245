import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  position: relative;
  z-index: 1;
`;

export const Description = styled.p`
  font-family: "Inter";
  font-size: 21px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  color: #f8f5f0;
  width: 100%;
  max-width: 678px;
  margin: 24px auto 0 auto;

  @media (max-width: 768px) {
    padding: 0 20px;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
  }
`;

export const TryLink = styled.a`
  text-decoration: none;
  display: inline-block;
  background-color: #fff;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  border-radius: 10px;
  color: rgba(0, 0, 0, 0.8);
  padding: 16.8px 34px;
  cursor: pointer;
  align-self: flex-start;
  text-align: center;
  margin: 51px auto 0 auto;
  letter-spacing: -0.03em;

  @media (max-width: 576px) {
    padding: 16.8px 87px;
    margin-top: 25px;
  }
`;
