import React from "react";

import Title from "./Title";
import { CHROME_STORE_EXTENSION_URL } from "../../../utils/constants";

import { Container, Description, TryLink } from "./styles";

function Benefits() {
  return (
    <Container>
      <Title />
      <Description>
        Reach is a super-simple yet powerful email & SMS campaign builder for
        anyone who wants expert-level results without being an expert
      </Description>
      <TryLink href={CHROME_STORE_EXTENSION_URL} target="_blank">
        Try Reach
      </TryLink>
    </Container>
  );
}

export default Benefits;
