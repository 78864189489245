import React from "react";

import {
  Container,
  Ellipse,
  LogosContainer,
  ReachLogoImage,
  HubspotLogoImage,
  BullhornLogoImage,
} from "./styles";

const logoList = [
  {
    id: 1,
    imgSrc: "images/reach-white-circle-logo.png",
    Element: ReachLogoImage,
  },
  {
    id: 2,
    imgSrc: "images/hubspot/hubspot-white-circle-logo.png",
    Element: HubspotLogoImage,
  },
  {
    id: 3,
    imgSrc: "images/bullhorn/bullhorn-white-circle-logo.png",
    Element: BullhornLogoImage,
  },
];

function FourthAnimation({ isStarted }) {
  return (
    <Container $isStarted={isStarted}>
      <Ellipse>
        <LogosContainer>
          {logoList.map(({ id, imgSrc, Element }) => (
            <Element key={id} src={imgSrc} alt="logo" />
          ))}
        </LogosContainer>
      </Ellipse>
    </Container>
  );
}

export default FourthAnimation;
