import React from "react";
import { useMediaQuery } from "react-responsive";

import {
  RETAIL_BENEFITS_DESCRIPTION,
  getRetailBenefits,
} from "../utils/constants";
import { BenefitsDescription } from "../components";
import HubspotBaseLanding from "./HubspotBaseLanding";

function HubspotForRetail() {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const benefitsList = getRetailBenefits(isMobile);
  const { part1, part2 } = RETAIL_BENEFITS_DESCRIPTION;

  return (
    <HubspotBaseLanding
      descriptionContent={() => (
        <BenefitsDescription part1={part1} part2={part2} />
      )}
      benefitsList={benefitsList}
    />
  );
}

export default HubspotForRetail;
