import styled from "styled-components";

export const StickyTableContainer = styled.div`
  width: fit-content;
`;

export const ScrollableTableContainer = styled.div`
  overflow-x: auto;
  padding-right: 1px;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #e6e3dc;
  }

  &::-webkit-scrollbar-thumb {
    background: #b8b8b8;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;
