import styled from "styled-components";

export const BackToHomeContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;

  width: 100%;
  height: 40px;
  background: #00000034;
  box-shadow: 0px 4px 14px 0px #0000000d;
`;

export const BackToHomeBtn = styled.div`
  width: fit-content;
  height: 21px;
  margin-top: 10px;
  margin-left: 20px;
  gap: 9px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #fff;
  font-family: ${({ theme }) => theme.fonts.interPrimary};
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  cursor: pointer;

  &:hover {
    opacity: 0.85;
  }

  img {
    width: 16px;
  }
`;

export const HeaderContainer = styled.div`
  position: fixed;
  top: 65px;
  left: 0;
  right: 0;
  padding: 0 7%;
  z-index: 2;

  @media (max-width: 977px) {
    padding-left: 0;
    padding-right: 0;
    top: 40px;
  }
`;

export const HeroSection = styled.div`
  background-color: #391952;
  position: relative;
  width: 100%;
  height: 453px;
  padding: 199px 7% 0;

  &::before {
    content: "";
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(
      rgba(204, 204, 204, 0.1) 1.5px,
      transparent 0
    );
    background-size: 20px 20px;
  }

  @media (max-width: 977px) {
    padding: 178px 15px 0;
    height: 368px;
  }
`;

export const HeroContent = styled.div`
  margin: 0 auto;
  width: 1240px;

  @media (max-width: 1440px) {
    margin: 0;
    width: 100%;
  }
`;

export const HeroTitle = styled.div`
  margin-left: 2px;
  width: 100%;
  max-width: 725px;
  color: #fff;
  font-family: ${({ theme }) => theme.fonts.interPrimary};
  font-size: 64px;
  font-weight: 800;
  line-height: 64px;
  letter-spacing: -0.02em;

  @media (max-width: 977px) {
    max-width: 425px;
    font-size: 38px;
    line-height: 38px;
  }
`;

export const HeroLastUpd = styled.div`
  margin: 16px 0 0;
  &,
  * {
    color: #fffcf5;
    font-family: ${({ theme }) => theme.fonts.interPrimary};
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
    span {
      font-weight: 400;
    }
  }

  @media (max-width: 977px) {
    margin-top: 10px;
    margin-left: 1px;
  }
`;

export const MainSection = styled.div`
  padding: 0 7%;

  @media (max-width: 977px) {
    padding: 0 15px;
  }
`;

export const MainContent = styled.div`
  margin: 75px auto 119px;
  width: 1240px;

  @media (max-width: 1440px) {
    margin: 62px 0 48px;
    width: 100%;
  }
`;
