import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";

import {
  AddedToWaitListMessage,
  ErrorPopup,
  Footer,
  Header,
  JoinToWaitList,
  PrimaryButton,
  PrimaryInput,
} from "../components";
import Benefits from "../components/Benefits";
import Preview from "../components/Preview";
import { addToWaitList } from "../utils/utils";
import { CHROME_STORE_EXTENSION_URL } from "../utils/constants";

function Landing({
  descriptionContent,
  benefitsList,
  postContent,
  isHomePage,
  titleImageSrc,
  videoThumbnailSrc,
  whiteListButtonText,
}) {
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [isDescriptionVisible, setsDescriptionVisible] = useState(true);
  const [recordsCount, setRecordsCount] = useState(null);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const goTo = (id) => {
    if (!id) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      return;
    }
    const section = document.getElementById(id);
    section.scrollIntoView({ behavior: "smooth" });
  };

  const joinToWaitList = async (value) => {
    const response = await addToWaitList(value);

    if (!response) {
      return;
    }

    if (typeof response === "string") {
      setPopupMessage(response);
      setIsShowPopup(true);
      setTimeout(() => {
        setPopupMessage("");
        setIsShowPopup(false);
      }, 2000);
      return;
    }

    if (!response.success) {
      setPopupMessage(response.message);
      setIsShowPopup(true);
      setTimeout(() => {
        setPopupMessage("");
        setIsShowPopup(false);
      }, 2000);
      return;
    }
    setsDescriptionVisible(false);
    setRecordsCount(response.recordsCount);
    goTo();
  };

  return (
    <PageContainer>
      <Header
        isMobile={isMobile}
        recordsCount={recordsCount}
        goToJoin={() => goTo("enter-email-to-join")}
      />
      <Preview
        isMobile={isMobile}
        isDescriptionVisible={isDescriptionVisible}
        videoThumbnailSrc={videoThumbnailSrc}
        isHomePage={isHomePage}
      >
        {recordsCount ? (
          <AddedToWaitListMessage recordsCount={recordsCount} color="white" />
        ) : isMobile ? (
          <PrimaryButton
            label={whiteListButtonText}
            isSmall={true}
            onClick={() => goTo("enter-email-to-join")}
          ></PrimaryButton>
        ) : (
          <PrimaryInput
            placeholder="Enter your email address"
            buttonText={whiteListButtonText}
            isBordered={false}
            isShadowed={false}
            onConfirm={joinToWaitList}
          />
        )}
      </Preview>
      <Benefits
        descriptionContent={descriptionContent}
        benefitsList={benefitsList}
        titleImageSrc={titleImageSrc}
        postContent={postContent}
      />
      <JoinToWaitList
        isMobile={isMobile}
        joinToWaitList={joinToWaitList}
        whiteListButtonText={whiteListButtonText}
      />
      <Footer />
      {isShowPopup && <ErrorPopup message={popupMessage}></ErrorPopup>}
    </PageContainer>
  );
}

export default Landing;

const PageContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.milk};
`;
