import styled from "styled-components";

export const Container = styled.div`
  padding: 85px 105px 90px;
  background-color: #fff;
  box-shadow: 0px 34px 26px -20px #0000000a;
  border-bottom: 1px solid #e2e2e2;

  @media (max-width: 767px) {
    padding: 60px 36px 50px;
    border: 1px solid #e2e2e2;
    border-radius: 10px;
  }
`;

export const CommentText = styled.p`
  font-family: Inter;
  font-size: 28px;
  font-weight: 800;
  line-height: 35.28px;
  letter-spacing: -0.02em;
  text-align: center;

  a {
    color: #612686;
    font-family: Inter;
    font-size: inherit;
    text-decoration: none;
    font-weight: 800;
  }

  @media (max-width: 767px) {
    font-size: 24px;
  }
`;
