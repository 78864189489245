import React, { useState, useEffect } from "react";

import {
  Container,
  PromptArea,
  MessagesArea,
  Cursor,
  TextBox,
  Text,
  GenerateBtn,
} from "./styles";
import Message from "../Message";

const FirstAnimation = ({ isStarted }) => {
  const [text, setText] = useState("");
  const [index, setIndex] = useState(0);

  const fullText = "Send discount to reviewers";

  const messages = [
    {
      text: "“Thanks for the 5 star reviews! Glad you loved the workout! How about another session at 20% off?",
      isOwn: false,
    },
    {
      text: "<b>Michael</b> - I’m sorry that you didn’t love the training session. Can we make it up to you by offering discounted make-up?",
      isOwn: true,
    },
    {
      text: "<b>Susan - thanks for the review! It was great to meet your husband. Would the two of you be interested in discounted session?</b>",
      isOwn: false,
    },
    { text: "...", isOwn: true },
  ];

  useEffect(() => {
    if (!isStarted) {
      return;
    }

    if (index < fullText.length) {
      const timeout = setTimeout(() => {
        setText((prevText) => `${prevText}${fullText[index]}`);
        setIndex((prevIndex) => prevIndex + 1);
      }, 100);

      return () => clearTimeout(timeout);
    }
  }, [index, fullText, isStarted]);

  return (
    <Container $isStarted={isStarted}>
      <PromptArea>
        <TextBox>
          <Text>{text}</Text>
        </TextBox>
        <GenerateBtn>Generate with Reach</GenerateBtn>
        <Cursor src="images/cursor.svg" alt="cursor" />
      </PromptArea>
      <MessagesArea>
        {messages.map((message, i) => (
          <Message key={i} {...message} isBigText />
        ))}
      </MessagesArea>
    </Container>
  );
};

export default FirstAnimation;
