import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
  useRef,
} from "react";
import { useScroll } from "framer-motion";

import Card from "./Card";
import FeatureTabs from "./Tabs";
import { FEATURE_CARDS_INFO, MOBILE_BREAKPOINT } from "../constants";

import { CardsContainer, Section, Title } from "./styles";

const FeaturesContainer = forwardRef((props, ref) => {
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < MOBILE_BREAKPOINT,
  );

  const container = useRef(null);
  const cardsContainer = useRef(null);

  const { scrollYProgress } = useScroll({
    target: cardsContainer,
    offset: ["start start", "end start"],
  });

  useImperativeHandle(ref, () => container.current, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < MOBILE_BREAKPOINT);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const scrollToCard = (index) => {
    const { top: cardsTop } = cardsContainer.current?.getBoundingClientRect();
    const { height } =
      cardsContainer.current?.children[0].getBoundingClientRect();

    const cardY = cardsTop + height * index + window.scrollY - 159;

    window.scrollTo({ top: cardY, behavior: "smooth" });
  };

  return (
    <Section ref={container}>
      <Title>Make more meaningful connections by leveraging Reach</Title>
      {!isMobile && (
        <FeatureTabs progress={scrollYProgress} onSetFeature={scrollToCard} />
      )}
      <CardsContainer ref={cardsContainer}>
        {FEATURE_CARDS_INFO.map((cardProps, index) => {
          const targetScale =
            1 - (FEATURE_CARDS_INFO.length - index - 1) * 0.05;
          return (
            <Card
              {...cardProps}
              key={index}
              animationParams={{
                progress: scrollYProgress,
                i: index,
                range: [index * 0.25, 1],
                targetScale,
                isMobile,
              }}
            />
          );
        })}
      </CardsContainer>
    </Section>
  );
});

export default FeaturesContainer;
