import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 160px;
  padding-top: 30px;
  z-index: 1;

  &:nth-child(3) {
    .image-preview {
      align-items: flex-end;
      width: 60%;
      padding-right: 20px;
    }
  }

  @media (max-width: 767px) {
    position: unset;

    &:nth-child(1) .image-preview {
      height: 502px;
      padding-top: 20px;
    }

    &:nth-child(2) .image-preview {
      padding-top: 40px;
    }

    &:nth-child(3) {
      .image-preview {
        height: 100%;
        width: 100%;
        padding: 0;
        padding-top: 40px;
      }
    }
  }
`;

export const Box = styled.div`
  background-color: ${({ $background }) => $background};
  width: 100%;
  display: flex;
  border-radius: 10px;
  margin-bottom: 20px;
  height: 460px;
  position: relative;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    height: unset;
    padding: 20px 20px 0;
  }
`;

export const Description = styled.div`
  padding-top: 75px;
  padding-left: 75px;
  width: 50%;
  color: #000;

  @media (max-width: 767px) {
    padding: 0;
    text-align: center;
    width: 100%;
  }
`;

export const Title = styled.h3`
  font-family: Inter;
  margin: 0;
  font-weight: 800;
  font-size: 36px;
  letter-spacing: -0.04em;
  line-height: 34.2px;

  @media (max-width: 767px) {
    font-size: 28px;
    line-height: 34.2px;
  }
`;

export const Text = styled.p`
  font-family: Inter;
  margin: 0;
  margin-top: 15px;
  font-size: 21px;
  font-weight: 400;

  @media (max-width: 767px) {
    font-size: 18px;
  }
`;

export const TryLink = styled.a`
  text-decoration: none;
  display: inline-block;
  font-family: Inter;
  margin-top: 35px;
  cursor: pointer;
  background-color: #000;
  text-align: center;
  letter-spacing: -0.03em;
  padding: 16.8px 34px;
  color: #fff;
  border-radius: 10px;
  border: none;
  font-size: 18px;
  font-weight: 600;

  @media (max-width: 767px) {
    margin-left: auto;
    margin-right: auto;
    padding: 17.5px 84.5px;
  }
`;

export const Preview = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  & > img {
    max-width: 100%;
  }

  @media (max-width: 767px) {
    width: 100%;
    padding-bottom: 50px;
    flex-grow: 1;
  }
`;
