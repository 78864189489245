import React from "react";

import Landing from "./Landing";

function BaseLanding({
  descriptionContent,
  benefitsList,
  postContent,
  isHomePage,
  // waitListButtonText,
  // waitListLinkUrl
}) {
  const titleImageSrc = "images/bullhorn-text.svg";
  const videoThumbnailSrc = "/images/bullhorn/video-thumbnail.png";
  const whiteListButtonText = "Start Your Free Trial";

  return (
    <Landing
      descriptionContent={descriptionContent}
      benefitsList={benefitsList}
      postContent={postContent}
      isHomePage={isHomePage}
      titleImageSrc={titleImageSrc}
      videoThumbnailSrc={videoThumbnailSrc}
      whiteListButtonText={whiteListButtonText}
    />
  );
}

export default BaseLanding;
