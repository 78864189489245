import React from "react";

import UserList from "./UserList";

import { Container, CommentText } from "./styles";

const Slide = ({ comments, commentInfo, onSelectUser }) => {
  const urlify = (text) => {
    const urlRegex =
      /(?:https?:\/\/|www\.)[^\s/$.?#].[^\s]*|(?:\b[a-zA-Z0-9.-]+\.[a-z]{2,}\b)/gi;

    return text.replace(urlRegex, (url) => {
      let href = url;

      if (!/^https?:\/\//i.test(url)) {
        href = "http://" + url;
      }

      return `<a href="${href}" target="_blank" rel="noopener noreferrer">${url}</a>`;
    });
  };

  return (
    <Container>
      <CommentText
        dangerouslySetInnerHTML={{ __html: `“${urlify(commentInfo.text)}"` }}
      />
      <UserList
        comments={comments}
        commentInfo={commentInfo}
        onSelect={onSelectUser}
      />
    </Container>
  );
};

export default Slide;
