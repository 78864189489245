import styled from "styled-components";
import PrimaryButton from "./PrimaryButton";
import { useState } from "react";

const PrimaryInput = ({
  placeholder,
  isBordered,
  isShadowed,
  onConfirm,
  buttonText,
}) => {
  const [value, setValue] = useState("");
  return (
    <InputContainer $isBordered={isBordered} $isShadowed={isShadowed}>
      <Input
        placeholder={placeholder}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onClick={() => {
          const _events = window.omEvents || [];
          _events.push(["ClickIntoEmailField"]);
        }}
      ></Input>
      <PrimaryButton
        label={buttonText}
        isInjectedButton={true}
        isBordered={isBordered}
        onClick={() => {
          onConfirm(value);
          setValue("");
        }}
      ></PrimaryButton>
    </InputContainer>
  );
};

export default PrimaryInput;

const InputContainer = styled.div`
  position: relative;
  border-radius: ${({ theme }) => theme.borderRadius.input};
  background-color: ${({ theme }) => theme.colors.white};
  border: ${({ $isBordered, theme }) =>
    $isBordered ? "1px solid " + theme.colors.lightGrey : "none"};
  width: 463px;
  height: 50px;
  box-shadow: ${({ $isShadowed }) =>
    $isShadowed ? "0 0 30px #0000001A" : "none"};
`;

const Input = styled.input`
  color: ${({ theme }) => theme.colors.deepGrey};
  font-size: ${({ theme }) => theme.fontSizes.normal};
  width: calc(100% - 200px);
  position: absolute;
  top: calc((100% - 20px) / 2);
  left: 20px;
  border: none;
  &::placeholder {
    color: ${({ theme }) => theme.colors.grey};
  }
  &:focus-visible {
    outline: none;
  }
`;
