import styled from "styled-components";

export const ConfirmPopupContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 290px;
  width: 479px;
  border-radius: 6px;
  border: 1px solid #cbcbcb;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 37px;
  z-index: 1001;
  box-shadow: 0px 0px 20px 0px #0000001a;
  background: linear-gradient(180deg, rgb(255, 251, 241), rgb(255, 255, 255));
`;

export const Image = styled.img`
  width: ${({ $imageStyles }) =>
    $imageStyles?.width ? $imageStyles?.width : "71px"};
  height: ${({ $imageStyles }) =>
    $imageStyles?.height ? $imageStyles?.height : "71px"};
`;

export const PopupTitle = styled.div`
  font-size: 18px;
  font-weight: 400;
  font-family: "AlbertSansExtraBold", sans-serif;
  margin-top: 15px;
  color: ${({ $titleStyles }) =>
    $titleStyles?.fontColor ? $titleStyles?.fontColor : "#000"};
`;

export const PopupDescription = styled.div`
  margin: 5px 0;
  font-size: 12px;
  font-weight: 400;
  font-family: "AlbertSans", sans-serif;
  color: #475467;
  text-align: center;
  width: 90%;
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000;
`;
