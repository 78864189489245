import styled from "styled-components";
import Logo from "./Logo";
import PrimaryInput from "./PrimaryInput";
import PrimaryButton from "./PrimaryButton";
import { useState } from "react";

const JoinToWaitList = ({ isMobile, joinToWaitList, whiteListButtonText }) => {
  const [email, setEmail] = useState("");
  return (
    <JoinToWaitListContainer id="enter-email-to-join">
      <Logo color="grey" width={isMobile ? "120" : "193"} />
      <Title>Get Early Access to Reach</Title>
      <Description>
        SalesStream.ai will select 100 users for their initial private beta and
        a further 1000 users for pre-release launch. Spots are running out
        quickly. Don’t miss out on this tremendous opportunity.&nbsp;
        <b>Request early access today.</b>
      </Description>
      {isMobile ? (
        <>
          <Input
            placeholder="Enter your email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onClick={() => {
              const _events = window.omEvents || [];
              _events.push(["ClickIntoEmailField"]);
            }}
          ></Input>
          <PrimaryButton
            label={whiteListButtonText}
            isMedium={true}
            margin="20px 0"
            onClick={() => {
              joinToWaitList(email);
              setEmail("");
            }}
          ></PrimaryButton>
        </>
      ) : (
        <PrimaryInput
          placeholder="Enter your email address"
          buttonText={whiteListButtonText}
          isBordered={!isMobile}
          isShadowed={!isMobile}
          onConfirm={joinToWaitList}
        ></PrimaryInput>
      )}
    </JoinToWaitListContainer>
  );
};

export default JoinToWaitList;

const JoinToWaitListContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 5vw 20px;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (max-width: 768px) {
    padding: 15vw 20px;
  }
`;

const Title = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${({ theme }) => theme.colors.titleGrey};
  font-weight: 800;
  font-family: ${({ theme }) => theme.fonts.bold};
  margin-top: 4vw;
  line-height: 23.04px;
  letter-spacing: -0.03em;

  @media (max-width: 768px) {
    margin-top: 10vw;
    font-size: ${({ theme }) => theme.fontSizes.xMedium};
    line-height: 14.4px;
  }
`;

const Description = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  opacity: 0.7;
  font-weight: 400;
  margin-top: 1vw;
  line-height: 19.6px;
  letter-spacing: -0.02em;
  text-align: center;
  width: 100%;
  max-width: 592px;
  margin-bottom: 4vw;

  @media (max-width: 768px) {
    font-weight: 400;
    font-size: ${({ theme }) => theme.fontSizes.small};
    line-height: 18.2px;
    max-width: 331px;
  }
`;

const Input = styled.input`
  border-radius: ${({ theme }) => theme.borderRadius.input};
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.deepGrey};
  font-size: ${({ theme }) => theme.fontSizes.normal};
  width: 288px;
  height: 36px;
  border: none;
  box-shadow: 0 0 30px #0000001a;
  padding-left: 10px;
  text-align: center;

  &::placeholder {
    color: ${({ theme }) => theme.colors.grey};
  }
  &:focus-visible {
    outline: none;
  }
`;
