import styled from "styled-components";

export const FooterContainer = styled.div`
  padding: 50px
    ${({ $forPrivacyAndTerms }) => ($forPrivacyAndTerms ? "7%" : "11.3%")} 29px;
  border-top: 1px solid #f7f3eb;

  @media (max-width: 767px) {
    padding: 25px 15px;
  }
`;

export const FooterContent = styled.div`
  margin: 0 auto;
  padding: ${({ $forPrivacyAndTerms }) => ($forPrivacyAndTerms ? "0 5.1%" : 0)};
  width: 1240px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: 1440px) {
    margin: 0;
    width: 100%;
  }

  @media (max-width: 767px) {
    padding: 0;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: flex-end;
    row-gap: 20px;
  }
`;

export const Links = styled.div`
  &,
  * {
    color: #000;
    font-family: ${({ theme }) => theme.fonts.interPrimary};
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
  }

  span {
    &:not(.spt) {
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    &.spt {
      margin: 1px 15px 0;
    }
  }

  @media (min-width: 768px) {
    padding-top: 3px;
  }
`;

export const LicenseContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Year = styled.div`
  color: #000000;
  font-family: ${({ theme }) => theme.fonts.interPrimary};
  font-size: 25px;
  font-weight: 900;
  line-height: 29px;

  @media (min-width: 768px) {
    position: relative;
    padding-right: 13px;
    &:after {
      content: " ";
      border-right: 1px solid #000;
      position: absolute;
      right: 7px;
      top: 3px;
      height: 24px;
    }
  }
`;

export const License = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  column-gap: 10px;

  &,
  * {
    color: #000000;
    font-family: ${({ theme }) => theme.fonts.interPrimary};
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
  }

  @media (min-width: 768px) {
    padding-top: 3px;
  }
`;
