import React, { useCallback, useMemo } from "react";
import { useMediaQuery } from "react-responsive";

import { useRotationWords } from "../../hooks";

import { Container, TextHighlight } from "./styles";

function Title() {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const { rotationWords, fade } = useRotationWords();
  const memoizedRenderTextHighlight = useCallback(
    (word, isFade) => renderTextHighlight(word, isFade),
    [],
  );
  const titleContent = useMemo(
    () => ({
      desktop: (
        <>
          {" "}
          Powerful{" "}
          {memoizedRenderTextHighlight(
            rotationWords.titleWord,
            fade.isTitleWordFade,
          )}
          <br />
          campaigns for{" "}
          {memoizedRenderTextHighlight(
            rotationWords.descriptionWord,
            fade.isDescriptionWordFade,
          )}
        </>
      ),
      mobile: (
        <>
          Powerful
          <br />
          {memoizedRenderTextHighlight(
            rotationWords.titleWord,
            fade.isTitleWordFade,
          )}
          <br />
          campaigns for
          <br />
          {memoizedRenderTextHighlight(
            rotationWords.descriptionWord,
            fade.isDescriptionWordFade,
          )}
        </>
      ),
    }),
    [rotationWords, fade, memoizedRenderTextHighlight],
  );

  return (
    <Container>
      {isMobile ? titleContent.mobile : titleContent.desktop}
    </Container>
  );
}

const renderTextHighlight = (word, isFade) => (
  <TextHighlight $isFade={isFade}>{word}</TextHighlight>
);

export default React.memo(Title);
