import styled from "styled-components";

const SmallMainTitle = ({ title }) => {
  return (
    <PreviewContainer>
      <Title>{title}</Title>
      {/* <Image src="images/safe-icon.png" alt="safe icon" /> */}
    </PreviewContainer>
  );
};

export default SmallMainTitle;

const PreviewContainer = styled.div`
  background-image: url("images/small-gradient-background.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 235px;
  display: flex;
  flex-direction: row;
  padding: 0 5vw;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    height: 150px;
  }
`;

const Title = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-weight: 800;
  font-size: ${({ theme }) => theme.fontSizes.xxLarge};
  font-family: ${({ theme }) => theme.fonts.poppinsBold};
  letter-spacing: -0.03em;
  width: 30vw;

  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.large};
    width: 50vw;
  }
`;

// const Image = styled.img`
//   width: 116px;

//   @media (max-width: 768px) {
//     width: 60px;
//   }
// `;
