import React from "react";

import { Table, TBody, TData, THead, THeader, TRow, TText } from "./styles";

function Scrollable({ headerData, contentData }) {
  return (
    <Table>
      <THeader>
        <TRow>
          {headerData.map((title, index, array) => {
            const isLastCell = index === array.length - 1;

            return (
              <THead key={index} $isLastCell={isLastCell}>
                {title}
              </THead>
            );
          })}
        </TRow>
      </THeader>
      <TBody>
        {contentData.map((row, rowIndex, array) => {
          return (
            <TRow key={rowIndex}>
              {row.map((cell, cellIndex) => {
                const isLastRow = rowIndex === array.length - 1;
                const isLastItem = cellIndex === row.length - 1;
                const isLastCell = isLastRow && isLastItem;

                return (
                  <TData key={cellIndex} $isLastCell={isLastCell}>
                    <TText>{cell}</TText>
                  </TData>
                );
              })}
            </TRow>
          );
        })}
      </TBody>
    </Table>
  );
}

export default Scrollable;
