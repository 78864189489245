import React from "react";
import styled from "styled-components";

import BenefitCard from "./BenefitCard";

function Benefits({
  descriptionContent,
  benefitsList,
  titleImageSrc,
  postContent,
}) {
  return (
    <BenefitsContainer>
      <TitleImage src={titleImageSrc} alt="benefits" />
      {descriptionContent()}
      <BenefitsList>
        {benefitsList.map((benefit, index) => (
          <BenefitCard key={index} benefit={benefit}></BenefitCard>
        ))}
      </BenefitsList>
      {postContent && postContent()}
      <PostContentTitle>See the difference Reach can make.</PostContentTitle>
    </BenefitsContainer>
  );
}

export default Benefits;

const BenefitsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10vw;
  padding: 0 20px;
`;

const TitleImage = styled.img`
  width: 100%;
  max-width: 516px;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    max-width: 301px;
  }
`;

const BenefitsList = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 884px;
  width: 100%;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const PostContentTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.large};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-weight: 800;
  letter-spacing: -0.03em;
  margin: 2vw 0;

  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.xMedium};
    line-height: 22.5px;
    margin-bottom: 15vw;
  }
`;
