import React from "react";
import styled from "styled-components";

function BenefitsDescription({ part1, part2 }) {
  return (
    <>
      <Description>{part1}</Description>
      <Description>
        <b>{part2}</b>
      </Description>
    </>
  );
}

export default BenefitsDescription;

const Description = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.text};
  max-width: 634px;
  text-align: center;
  line-height: 25.2px;

  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.small};
    max-width: 317px;
    line-height: 18.2px;
  }
`;
