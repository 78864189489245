import React from "react";

import { Table, TBody, TData, THead, THeader, TRow, TText } from "./styles";

function Sticky({ headerData, contentData }) {
  return (
    <Table>
      <THeader>
        <TRow>
          {headerData.map((title, index) => (
            <THead key={index}>{title}</THead>
          ))}
        </TRow>
      </THeader>
      <TBody>
        {contentData.map((row, rowIndex, array) => {
          return (
            <TRow key={rowIndex}>
              {row.map((cell, cellIndex) => {
                const isLastRow = rowIndex === array.length - 1;
                const isLastItem = cellIndex === row.length - 1;
                const isBeforeLastItem = cellIndex === row.length - 2;
                const isLastCell = isLastRow && isLastItem;
                const isBeforeLastCell = isLastRow && isBeforeLastItem;

                return (
                  <TData
                    key={cellIndex}
                    $isLastCell={isLastCell}
                    $isBeforeLastCell={isBeforeLastCell}
                  >
                    <TText>{cell}</TText>
                  </TData>
                );
              })}
            </TRow>
          );
        })}
      </TBody>
    </Table>
  );
}

export default Sticky;
