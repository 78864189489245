import {
  addEmailToWaitList,
  FORCE_CORPORATE_EMAILS,
} from "../services/waitList";
import { EMAIL_REGEX } from "./constants";

const validateEmail = (email) => {
  return EMAIL_REGEX.test(email);
};

const addToWaitList = async (email, isNeedAddEvent = false) => {
  if (email === "") {
    return;
  }

  let invalidEmailMessage;
  if (!validateEmail(email)) {
    invalidEmailMessage = "Invalid email address";
  } else if (
    (email.toString().toLowerCase().endsWith("gmail.com") ||
      email.toString().toLowerCase().endsWith("yahoo.com") ||
      email.toString().toLowerCase().endsWith("hotmail.com") ||
      email.toString().toLowerCase().endsWith("outlook.com") ||
      email.toString().toLowerCase().endsWith("mail.com") ||
      email.toString().toLowerCase().endsWith("icloud.com")) &&
    FORCE_CORPORATE_EMAILS
  ) {
    invalidEmailMessage = "Please use your company email address";
  }

  if (invalidEmailMessage) {
    return invalidEmailMessage;
  }

  if (isNeedAddEvent) {
    const _events = window.omEvents || [];
    _events.push(["JoinWaitlist"]);
  }

  return await addEmailToWaitList(email);
};

const sanitizeHtml = (html) => {
  // Sanitize the text to remove any potentially dangerous HTML
  return html.replace(/<script.*?>.*?<\/script>/gi, "");
};

export { addToWaitList, sanitizeHtml };
