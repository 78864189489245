import React, { useRef, useState } from "react";

import { Container, Video, ThumbnailOverlay } from "./styles";

function IntroVideo({ videoUrl, altVideoUrl, videoThumbnailSrc }) {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlay = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
        setIsPlaying(true);
      } else {
        videoRef.current.pause();
        setIsPlaying(false);
      }
      videoRef.current.muted = false;
    }
  };

  const handleVideoEnd = () => {
    setIsPlaying(false);
  };

  return (
    <Container>
      <Video ref={videoRef} onClick={togglePlay} onEnded={handleVideoEnd}>
        <source src={videoUrl} type="video/webm" />
        <source src={altVideoUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </Video>
      <ThumbnailOverlay
        onClick={togglePlay}
        $isPlaying={isPlaying}
        $videoThumbnailSrc={videoThumbnailSrc}
      />
    </Container>
  );
}

export default IntroVideo;
