import React, { useState, useEffect, useRef } from "react";
import { motion, useTransform } from "framer-motion";

import {
  Container,
  Box,
  Description,
  Preview,
  Text,
  Title,
  TryLink,
} from "./styles";
import FirstAnimation from "./Animation/First";
import SecondAnimation from "./Animation/Second";
import ThirdAnimation from "./Animation/Third";
import FourthAnimation from "./Animation/Fourth";
import { CHROME_STORE_EXTENSION_URL } from "../../../../utils/constants";

const Card = ({ backgroundColor, title, text, animationParams }) => {
  const previewRef = useRef(null);
  const [isInView, setIsInView] = useState(false);
  const { i, progress, range, targetScale, isMobile } = animationParams;

  const checkIsInView = () => {
    if (!previewRef.current) {
      return;
    }

    const currentScale = scale.get();
    const rect = previewRef.current.getBoundingClientRect();

    const reducedProgress =
      targetScale === 1 ? 0 : (1 - currentScale) / (1 - targetScale);

    const isCardOnTop = reducedProgress <= 0.2;
    if (rect.bottom <= window.innerHeight && rect.top >= 0 && isCardOnTop) {
      setIsInView(true);
    }

    const isCardSmall = targetScale !== 1 && reducedProgress > 0.2;
    if (rect.bottom <= 0 || rect.top >= window.innerHeight || isCardSmall) {
      setIsInView(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", checkIsInView);
    return () => window.removeEventListener("scroll", checkIsInView);
  }, []);

  const scale = useTransform(progress, range, [1, targetScale]);

  const styles = isMobile
    ? {}
    : {
        container: { transform: `translate-y: ${scale.get}px` },
        box: { scale, top: `${i * 25}px` },
      };

  const Animation = (params) => {
    const animations = [
      FirstAnimation,
      SecondAnimation,
      FourthAnimation,
      ThirdAnimation,
    ];
    return animations[i]?.(params);
  };

  return (
    <Container style={styles.container}>
      <Box as={motion.div} style={styles.box} $background={backgroundColor}>
        <Description>
          <Title>{title}</Title>
          <Text>{text}</Text>
          <TryLink href={CHROME_STORE_EXTENSION_URL} target="_blank">
            Try Reach
          </TryLink>
        </Description>
        <Preview ref={previewRef} className="image-preview">
          <Animation isStarted={isInView} />
        </Preview>
      </Box>
    </Container>
  );
};

export default Card;
