import styled from "styled-components";

export const THead = styled.th`
  font-family: "Inter";

  ${({ $isLastCell }) =>
    $isLastCell &&
    `
      border-top-right-radius: 5px;
    `}
`;

export const TData = styled.td`
  font-family: "Inter";

  ${({ $isLastCell }) =>
    $isLastCell &&
    `
      border-bottom-right-radius: 5px;
    `}
`;

export const THeader = styled.thead``;

export const TRow = styled.tr``;

export const TBody = styled.tbody``;

export const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  table-layout: fixed;

  ${THead} {
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    width: 230px;
    height: 60px;
    border-top: 1px solid #ecebe8;
    border-right: 1px solid #ecebe8;
    border-bottom: 1px solid #ecebe8;
    padding: 0 43px;

    @media (max-width: 768px) {
      font-size: 14px;
      width: 135px;
      padding: 0 13px;
    }
  }

  ${TData} {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    width: 230px;
    height: 60px;
    border-right: 1px solid #ecebe8;
    border-bottom: 1px solid #ecebe8;
    padding: 0 43px;

    @media (max-width: 768px) {
      font-size: 12px;
      width: 135px;
      padding: 0 13px;
    }
  }
`;

export const TText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
`;
