import styled from "styled-components";
import { INFO_EMAIL } from "../utils/constants";

const ContactsInfoCard = () => {
  return (
    <Card>
      <Container>
        <Title>Contact</Title>
        <Text>
          If you have any questions, please contact us at <span> {INFO_EMAIL}</span>
        </Text>
      </Container>
      {/*<Text $margin="0 0 0 24px">*/}
      {/*  By email:*/}
      {/*  <span> {INFO_EMAIL}</span>*/}
      {/*</Text>*/}
    </Card>
  );
};

export default ContactsInfoCard;

const Card = styled.div``;

const Container = styled.div`
  width: 40vw;
  margin-top: calc(2vw + 68px);
  padding-left: 20px;
  border-left: 4px solid ${({ theme }) => theme.colors.green};
`;

const Title = styled.div`
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSizes.text};
  font-family: ${({ theme }) => theme.fonts.poppinsBold};
  color: ${({ theme }) => theme.colors.green};
  margin-bottom: 8px;
`;

const Text = styled.div`
  ${({ $margin }) => $margin && `margin: ${$margin};`}
  font-weight: 400;
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: #4a4a4a;
  padding: 8px 0;

  span {
    color: ${({ theme }) => theme.colors.green};
  }
`;
