import styled, { css } from "styled-components";

export const Container = styled.div`
  padding: 20px 10px 15px 65px;
  max-width: 410px;
  min-height: 90px;
  border-radius: 36px;

  ${({ $isOwn }) =>
    $isOwn
      ? css`
          background: #fbfec4;
          border-bottom-right-radius: 0;
          align-self: flex-end;
        `
      : css`
          background: #fff;
          border-bottom-left-radius: 0;
          align-self: flex-start;
        `}

  @media (max-width: 767px) {
    padding-left: 30px;
    max-width: 300px;
  }
`;

export const Text = styled.p`
  font-family: Inter;

  letter-spacing: -0.02em;
  text-align: left;
  color: #444444;
  opacity: 0.9;
  line-height: 1.4em;
  font-weight: 500;

  b {
    font-weight: 600;
  }

  ${(props) =>
    props.$isBigText
      ? css`
          font-size: 17px;
          width: 335px;

          @media (max-width: 1200px) {
            width: 250px;
          }
        `
      : css`
          font-size: 13.82px;
        `}

  @media (max-width: 1200px) {
    font-size: 13px;
  }
`;
