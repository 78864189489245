const theme = Object.freeze({
  colors: {
    purple: "#471168",
    white: "#ffffff",
    semiWhite: "#EFEFEF",
    grey: "#BDBDBD",
    lightGrey: "#DEDEDE",
    titleGrey: "#6C6C6C",
    deepGrey: "#1A202C",
    pageGrey: "#f3f3f3",
    milk: "#f8f8f8",
    blue: "#596780",
    footer: "#ebebeb",
    border: "#E4E4E4",
    green: "#6AC78C",
  },
  borderRadius: {
    button: "63px",
    injectedButton: "53px",
    input: "88px",
    normal: "7px",
  },
  fonts: {
    primary: "'Roboto', sans-serif",
    bold: "'RobotoBold', sans-serif",
    thin: "'RobotoLight', sans-serif",
    poppinsPrimary: "'Poppins', sans-serif",
    poppinsBold: "'PoppinsBold', sans-serif",
    poppinsLight: "'PoppinsLight', sans-serif",
    interPrimary: "'Inter', sans-serif",
  },
  fontSizes: {
    button: "16px",
    xSmall: "12px",
    small: "13px",
    medium: "14px",
    xMedium: "15px",
    normal: "16px",
    text: "18px",
    xNormal: "20px",
    large: "24px",
    xLarge: "32px",
    xxLarge: "40px",
    xxxLarge: "60px",
  },
});

export default theme;
