import React, { useState, useEffect, useRef } from "react";

import { Container, Tab } from "./styles";
import { FEATURE_TAB_LIST } from "../../constants";

const FeatureTabs = ({ progress, onSetFeature }) => {
  const container = useRef(null);
  const [activeFeature, setActiveFeature] = useState(0);

  useEffect(() => {
    progress.on("change", () => {
      const index =
        progress.current > 0.6 ? 3 : Math.floor(progress.current * 1.67 * 4);

      setActiveFeature(index);
    });
  }, []);

  return (
    <Container ref={container}>
      {FEATURE_TAB_LIST.map((tab, i) => (
        <Tab
          key={i}
          $isActive={activeFeature === i}
          onClick={() => onSetFeature(i)}
        >
          {tab}
        </Tab>
      ))}
    </Container>
  );
};

export default FeatureTabs;
