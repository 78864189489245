import React from "react";
import { useMediaQuery } from "react-responsive";

import {
  BENEFITS_DESCRIPTION,
  getBullhornPage1Benefits,
} from "../utils/constants";
import { BenefitsDescription, PostContent } from "../components";
import BaseLanding from "./BaseLanding";

function BullhornPage1() {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const benefitsList = getBullhornPage1Benefits(isMobile);
  const { part1, part2 } = BENEFITS_DESCRIPTION;

  return (
    <BaseLanding
      descriptionContent={() => (
        <BenefitsDescription part1={part1} part2={part2} />
      )}
      benefitsList={benefitsList}
      postContent={() => <PostContent />}
    />
  );
}

export default BullhornPage1;
