import React from "react";

import { Container, Cursor, SelectionArea } from "./styles";

const ThirdAnimation = ({ isStarted }) => {
  return (
    <Container $isStarted={isStarted}>
      <img src="images/feat-3.png" alt="feature" />
      <Cursor />
      <SelectionArea />
    </Container>
  );
};

export default ThirdAnimation;
