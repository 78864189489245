import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 1125px;
  display: flex;

  @media (max-width: 768px) {
    width: 99%;
  }
`;

export const Video = styled.video`
  width: 99%;
  height: auto;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: none;

  @media (max-width: 768px) {
    box-shadow: 0 0 250px rgba(0, 0, 0, 0.25);
  }
`;

export const ThumbnailOverlay = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  border-radius: ${({ theme }) => theme.borderRadius.normal};
  height: 101%;
  width: 101%;
  display: ${({ $isPlaying }) => ($isPlaying ? "none" : "flex")};
  background-image: url(${({ $videoThumbnailSrc }) => $videoThumbnailSrc});
  background-repeat: no-repeat;
  background-position: center;
  background-size: ${({ $videoThumbnailSrc }) =>
    $videoThumbnailSrc ? "101%" : "0%"};
  border: 1px solid #ededed;
`;
