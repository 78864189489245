import { useEffect, useRef, useState } from "react";
import { ROTATION_WORD_LIST } from "./constants";

export const useRotationWords = () => {
  const PAUSE_TIME_MS = 3000;
  const FADE_TIME_MS = 500;

  const [rotationWords, setRotationWords] = useState({
    titleWord: ROTATION_WORD_LIST[0].titleWord,
    descriptionWord: ROTATION_WORD_LIST[0].descriptionWord,
  });
  const [fade, setFade] = useState({
    isTitleWordFade: false,
    isDescriptionWordFade: false,
  });

  const counterRef = useRef(0);
  const isTitleWordChangeReadyRef = useRef(true);
  const isDescriptionWordChangeReadyRef = useRef(false);

  function handleTitleWord() {
    setFade((prevState) => ({
      ...prevState,
      isTitleWordFade: true,
    }));

    setTimeout(() => {
      isTitleWordChangeReadyRef.current = false;
      isDescriptionWordChangeReadyRef.current = true;

      setRotationWords((prevState) => ({
        ...prevState,
        titleWord: ROTATION_WORD_LIST[counterRef.current].titleWord,
      }));
      setFade((prevState) => ({
        ...prevState,
        isTitleWordFade: false,
      }));

      ++counterRef.current;
    }, FADE_TIME_MS);
  }

  function handleDescriptionWord() {
    setFade((prevState) => ({
      ...prevState,
      isDescriptionWordFade: true,
    }));

    setTimeout(() => {
      isDescriptionWordChangeReadyRef.current = false;
      isTitleWordChangeReadyRef.current = true;

      setRotationWords((prevState) => ({
        ...prevState,
        descriptionWord: ROTATION_WORD_LIST[counterRef.current].descriptionWord,
      }));
      setFade((prevState) => ({
        ...prevState,
        isDescriptionWordFade: false,
      }));
    }, FADE_TIME_MS);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (counterRef.current >= ROTATION_WORD_LIST.length - 1) {
        clearInterval(interval);
      }

      if (isTitleWordChangeReadyRef.current) {
        handleTitleWord();
      }

      if (isDescriptionWordChangeReadyRef.current) {
        handleDescriptionWord();
      }
    }, FADE_TIME_MS + PAUSE_TIME_MS);

    return () => clearInterval(interval);
  }, []);

  return {
    rotationWords,
    fade,
  };
};
