import React from "react";
import styled from "styled-components";
import { useState } from "react";
import theme from "../../../theme";
import { addToWaitList } from "../../../utils/utils";
import { AddedToWaitListMessage, ErrorPopup } from "../../../components";

const EmailInput = () => {
  const [value, setValue] = useState("");
  const [popupMessage, setPopupMessage] = useState(null);

  const joinToWaitList = async () => {
    const response = await addToWaitList(value, true);

    setValue("");

    if (!response) {
      return;
    }

    const showPopup = (msg, timeout) => {
      setPopupMessage(msg);
      setTimeout(() => {
        setPopupMessage(null);
      }, timeout || 2000);
    };

    if (typeof response === "string") {
      showPopup(response);
      return;
    }

    if (!response.success) {
      showPopup(response.message);
      return;
    }

    const { recordsCount } = response;
    if (recordsCount) {
      showPopup(
        <AddedToWaitListMessage recordsCount={recordsCount} color="white" />,
        3000,
      );
    }
  };

  return (
    <>
      {!!popupMessage && (
        <ErrorPopup
          message={popupMessage}
          {...(typeof popupMessage === "string"
            ? {}
            : { bgColor: theme.colors.purple })}
        ></ErrorPopup>
      )}
      <InputContainer>
        <Input
          placeholder="Enter your e-mail address"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onBlur={(e) => setValue(e.target.value.trim())}
          onClick={() => {
            const _events = window.omEvents || [];
            _events.push(["ClickIntoEmailField"]);
          }}
        />
        <Button type="button" onClick={joinToWaitList} />
      </InputContainer>
    </>
  );
};

export default EmailInput;

const BTN_WIDTH = "90px";
const BTN_WIDTH_MOBILE = "78px";

const COL_GAP = "19px";

const BORDER_WIDTH = "1px";

const INPUT_PADDING = "3px";
const INPUT_PADDING_MOBILE = "7px";

const InputContainer = styled.div`
  position: relative;
  border-radius: 10px;
  height: 64px;
  width: 100%;
  max-width: 777px;
`;

const Input = styled.input`
  padding: ${INPUT_PADDING} calc(${INPUT_PADDING} + ${BTN_WIDTH} + ${COL_GAP})
    ${INPUT_PADDING} 34px;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: ${BORDER_WIDTH} solid #f3f3f3;
  background-color: #ffffff;
  color: #000;
  font-family: ${({ theme }) => theme.fonts.interPrimary};
  font-size: 20px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.02em;

  @media (max-width: 767px) {
    padding: ${INPUT_PADDING_MOBILE}
      calc(${INPUT_PADDING_MOBILE} + ${BTN_WIDTH_MOBILE} + ${COL_GAP})
      ${INPUT_PADDING_MOBILE} 19px;
    font-size: 16px;
    line-height: 23px;
  }

  outline: none !important;
  box-shadow: none !important;

  &::placeholder {
    color: rgba(0, 0, 0, 0.8);
  }

  &:focus {
    border-color: #d5d5d5;
  }
`;

const Button = styled.button`
  cursor: pointer;
  width: ${BTN_WIDTH};
  height: 56px;
  border-radius: 10px;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  background-color: #612686;
  background-image: url(/images/arrow-right.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 33px 30px;
  position: absolute;
  top: calc(${INPUT_PADDING} + ${BORDER_WIDTH});
  right: calc(${INPUT_PADDING} + ${BORDER_WIDTH});

  @media (max-width: 767px) {
    top: calc(${INPUT_PADDING_MOBILE} + ${BORDER_WIDTH});
    right: calc(${INPUT_PADDING_MOBILE} + ${BORDER_WIDTH});
    width: ${BTN_WIDTH_MOBILE};
    height: 48px;
  }

  &:hover {
    opacity: 0.85;
  }
`;
