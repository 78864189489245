import React from "react";
import styled from "styled-components";

function PostContent() {
  return (
    <PostContentContainer>
      No complex sequences. No learning curve. Get 1 on 1 personalized
      communication at scale with Reach.
    </PostContentContainer>
  );
}

export default PostContent;

const PostContentContainer = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.xNormal};
  max-width: 819px;
  width: 100%;
  text-align: center;
  margin-top: 4vw;
  line-height: 30px;
  font-weight: 400;

  @media (max-width: 768px) {
    margin-top: 20vw;
    font-size: ${({ theme }) => theme.fontSizes.xMedium};
    line-height: 22.5px;
  }
`;
