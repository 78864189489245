import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  *,
  *::before,
  *::after {
    animation-play-state: ${(props) =>
      props.$isStarted ? "running" : "paused"};
  }
`;

export const TextBox = styled.div`
  width: 100%;
  height: 79px;
  border-radius: 12px;
  background: #fff;
  padding: 27px 35px;
`;

export const Text = styled.span`
  position: relative;
  &::after {
    content: "|";
    color: #000;
    font-size: 1.3em;
    position: absolute;
    right: -8px;
    transform: translateY(-50%);
    top: 50%;
    animation-name: blinker;
    animation-iteration-count: infinite;
    animation-duration: 1s;
  }

  @keyframes blinker {
    from,
    to {
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
  }
`;

export const PromptArea = styled.div`
  padding: 0 14%;
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  opacity: 0;
  animation-name: hide-area;
  animation-iteration-count: 1;
  animation-timing-function: linear(0 0%, 0 95%, 0.95 99%, 1 100%);
  animation-duration: 4.5s;

  @media (max-width: 767px) {
    padding: 0;
  }

  @keyframes hide-area {
    0% {
      opacity: 1;
      height: fit-content;
    }
    95% {
      height: fit-content;
      opacity: 0;
    }
    100% {
      opacity: 0;
      height: 0;
    }
  }
`;

export const MessagesArea = styled.div`
  height: fit-content;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 0 50px;
  transform: translateY(-12px);
  transition: 0.3s all;

  animation-name: move-messages;
  animation-iteration-count: 1;
  animation-duration: 10s;

  @keyframes move-messages {
    0% {
      transform: translateY(455px);
      height: 0;
    }
    49% {
      transform: translateY(455px);
      height: 0;
    }
    50% {
      height: fit-content;
      transform: translateY(455px);
    }
    60% {
      height: fit-content;
      transform: translateY(335px);
    }
    70% {
      height: fit-content;
      transform: translateY(214px);
    }
    80% {
      height: fit-content;
      transform: translateY(93px);
    }
    90% {
      height: fit-content;
      transform: translateY(-12px);
    }
    100% {
      height: fit-content;
      transform: translateY(-12px);
    }
  }

  @media (max-width: 767px) {
    padding: 0 10px;
  }
`;

export const GenerateBtn = styled.div`
  margin: 33px auto 0;
  width: 205px;
  color: #fff;
  border-radius: 10px;
  padding: 16px 18px 16px 40px;
  background-color: #612686;
  font-family: Inter;
  font-size: 15.68px;
  font-weight: 600;
  line-height: 28.75px;
  letter-spacing: -0.04em;
  text-align: left;
  background-image: url("images/star.svg");
  background-repeat: no-repeat;
  background-position: 13px center;
`;

export const Cursor = styled.div`
  width: 48px;
  height: 49px;
  mask: url("images/cursor.svg") no-repeat center;
  position: absolute;
  top: 55%;
  left: 45%;
  background-color: #fff;
  transform: scale(1.1);
  animation-name: move-cursor;
  animation-iteration-count: 1;
  animation-timing-function: linear(0 0%, 0 70%, 0.9 90%, 1 100%);
  animation-duration: 4s;

  @keyframes move-cursor {
    0% {
      top: 40%;
      left: 67%;
      transform: scale(1);
      background-color: #000;
    }
    90% {
      top: 55%;
      left: 45%;
      transform: scale(1);
      background-color: #ddd;
    }
    100% {
      top: 55%;
      left: 45%;
      transform: scale(1.2);
      background-color: #fff;
    }
  }
`;
