import styled from "styled-components";

export const Section = styled.section`
  padding: 120px 0 20px;
`;

export const CardsContainer = styled.div`
  margin-top: -510px;
`;

export const Title = styled.h2`
  font-family: Inter;
  font-weight: 800;
  color: #141414;
  font-size: 36px;
  line-height: 1em;
  letter-spacing: -0.02em;
  text-align: center;
  max-width: 682px;
  margin: 0 auto;
  margin-bottom: 68px;
  position: sticky;
  top: 0;
  padding-bottom: 530px;

  @media (max-width: 767px) {
    position: unset;
    margin-bottom: 10px;
    font-size: 28px;
  }
`;
