import styled from "styled-components";

export const THead = styled.th`
  font-family: "Inter";
`;

export const TData = styled.td`
  font-family: "Inter";

  ${({ $isLastCell }) =>
    $isLastCell &&
    `
      &::before {
        content: "";
        position: absolute;
        bottom: -35px;
        right: 0;
        width: 100%;
        height: 35px;
        background-color: #5e2482;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        -webkit-box-shadow: 20px 0px 8px -14px rgba(0, 0, 0, 0.2) inset;
        -moz-box-shadow: 20px 0px 8px -14px rgba(0, 0, 0, 0.2) inset;
        box-shadow: 20px 0px 8px -14px rgba(0, 0, 0, 0.2) inset;
      }
    `}

  ${({ $isBeforeLastCell }) =>
    $isBeforeLastCell &&
    `
      border-bottom-left-radius: 5px;
    `}
`;

export const THeader = styled.thead``;

export const TRow = styled.tr``;

export const TBody = styled.tbody``;

export const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  border-top-left-radius: 5px;
  table-layout: fixed;
  position: relative;
  -webkit-box-shadow: -16px 0px 25px -12px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: -16px 0px 25px -12px rgba(34, 60, 80, 0.2);
  box-shadow: -16px 0px 25px -12px rgba(34, 60, 80, 0.2);

  @media (max-width: 768px) {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 190px;
    height: 50px;
    z-index: 0;
    -webkit-box-shadow: 0px -16px 25px -12px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px -16px 25px -12px rgba(34, 60, 80, 0.2);
    box-shadow: 0px -16px 25px -12px rgba(34, 60, 80, 0.2);

    @media (max-width: 768px) {
      display: none;
    }
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 190px;
    height: 60px;
    z-index: 0;
    transform: rotate(180deg);
    -webkit-box-shadow: 0px -16px 25px -12px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px -16px 25px -12px rgba(34, 60, 80, 0.2);
    box-shadow: 0px -16px 25px -12px rgba(34, 60, 80, 0.2);

    @media (max-width: 768px) {
      display: none;
    }
  }

  ${THead} {
    &:first-child {
      text-align: right;
      font-size: 16px;
      font-weight: 700;
      width: 190px;
      height: 60px;
      border-left: 1px solid #ecebe8;
      border-bottom: 1px solid #ecebe8;
      padding-right: 14px;
      background-color: #fff;
      border-top-left-radius: 5px;

      @media (max-width: 768px) {
        font-size: 12px;
        width: 120px;
      }
    }

    &:nth-child(2) {
      text-align: center;
      font-size: 25px;
      font-weight: 800;
      width: 250px;
      height: 60px;
      color: #fff;
      background-color: #5e2482;
      position: relative;
      border-bottom: 1px solid #76359f;
      padding: 0 43px 24px 43px;
      -webkit-box-shadow: 20px 0px 8px -14px rgba(0, 0, 0, 0.2) inset;
      -moz-box-shadow: 20px 0px 8px -14px rgba(0, 0, 0, 0.2) inset;
      box-shadow: 20px 0px 8px -14px rgba(0, 0, 0, 0.2) inset;

      &::before {
        content: "";
        position: absolute;
        top: -25px;
        right: 0;
        width: 100%;
        height: 25px;
        background-color: #5e2482;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        -webkit-box-shadow: 20px 0px 8px -14px rgba(0, 0, 0, 0.2) inset;
        -moz-box-shadow: 20px 0px 8px -14px rgba(0, 0, 0, 0.2) inset;
        box-shadow: 20px 0px 8px -14px rgba(0, 0, 0, 0.2) inset;
      }

      @media (max-width: 768px) {
        width: 140px;
        padding: 0 8px 5px 8px;
        border-bottom: none;
      }
    }
  }

  ${TData} {
    &:first-child {
      text-align: right;
      font-size: 14px;
      font-weight: 800;
      width: 190px;
      height: 60px;
      border-bottom: 1px solid #ecebe8;
      border-left: 1px solid #ecebe8;
      padding-right: 14px;
      background-color: #fff;

      @media (max-width: 768px) {
        font-size: 12px;
        width: 120px;
      }
    }

    &:nth-child(2) {
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      width: 250px;
      height: 60px;
      color: #fff;
      border-bottom: 1px solid #76359f;
      background-color: #5e2482;
      position: relative;
      padding: 0 43px;
      -webkit-box-shadow: 20px 0px 8px -14px rgba(0, 0, 0, 0.2) inset;
      -moz-box-shadow: 20px 0px 8px -14px rgba(0, 0, 0, 0.2) inset;
      box-shadow: 20px 0px 8px -14px rgba(0, 0, 0, 0.2) inset;

      @media (max-width: 768px) {
        font-size: 12px;
        width: 140px;
        padding: 0 8px;
      }
    }
  }
`;

export const TText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
`;
