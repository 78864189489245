import React from "react";

import Tables from "./Tables";

import { Container, Description, TablesContainer, Title } from "./styles";

function Recommendation() {
  return (
    <Container>
      <Title>What makes Reach different</Title>
      <Description>
        More personalized than your marketing email provider, more scalable than
        your CRM’s email writer, Reach is in a category all its own
      </Description>
      <TablesContainer>
        <Tables />
      </TablesContainer>
    </Container>
  );
}

export default Recommendation;
