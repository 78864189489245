import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const Footer = () => {
  const navigate = useNavigate();

  const goTo = (path) => {
    navigate(path);
  };

  const getFullYear = () => {
    const date = new Date();
    return date.getFullYear();
  };
  return (
    <FooterContainer>
      <Links>
        <span onClick={() => goTo("/privacy")}>Privacy Policy </span> |
        <span onClick={() => goTo("/terms-conditions")}>
          &nbsp;Terms & Conditions
        </span>
      </Links>
      <License>© SalesStream.ai {getFullYear()}</License>
    </FooterContainer>
  );
};

export default Footer;

const FooterContainer = styled.div`
  height: 88px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 5vw;

  @media (max-width: 768px) {
    height: 200px;
    background-color: ${({ theme }) => theme.colors.footer};
    flex-direction: column;
    padding: 15vw 0 0 0;
    justify-content: flex-start;
  }
`;

const Links = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  color: ${({ theme }) => theme.colors.deepGrey};
  font-weight: 600;
  opacity: 0.4;
  span {
    cursor: pointer;
  }

  @media (max-width: 768px) {
    opacity: 1;
    margin-bottom: 2vw;
  }
`;

const License = styled.div`
  color: ${({ theme }) => theme.colors.blue};
  font-size: ${({ theme }) => theme.fontSizes.normal};
  font-weight: 500;
  letter-spacing: -0.02em;
  opacity: 0.5;

  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.small};
  }
`;
