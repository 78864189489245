import React from "react";
import styled from "styled-components";
import TextBlock from "./TextBlock";
import {
  CONTACT_EMAIL,
  ADDRESS,
  COMPANY_NAME,
  CONTACTING_US_TITLE,
} from "../../utils/constants";

const PageContent = ({ content }) => {
  const contactingUsItem = content.find(
    (item) => item.title === CONTACTING_US_TITLE
  );

  return (
    <Content>
      <TextSection>
        {content.map(
          (item, index) =>
            item.title !== CONTACTING_US_TITLE && (
              <TextBlock
                key={index}
                title={item.title}
                textArray={item.textArray}
              />
            )
        )}
      </TextSection>
      <ContactUs>
        <TextBlock
          title={contactingUsItem.title}
          textArray={contactingUsItem.textArray}
        />
        <ContactsTitle>{COMPANY_NAME}</ContactsTitle>
        <Contacts>
          {ADDRESS.map((item, index) => (
            <p key={index}>{item}</p>
          ))}
          <p>
            <span>E-mail</span> {CONTACT_EMAIL}
          </p>
        </Contacts>
      </ContactUs>
    </Content>
  );
};

export default PageContent;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 6.7%;

  @media (max-width: 977px) {
    flex-direction: column;
    row-gap: 67px;
  }
`;

const ContactUs = styled.div`
  width: 29.76%;

  @media (max-width: 977px) {
    width: 100%;
  }
`;

const TextSection = styled.div`
  width: 63.63%;

  @media (max-width: 977px) {
    width: 100%;
  }

  display: flex;
  flex-direction: column;
  row-gap: 67px;
`;

const ContactsTitle = styled.div`
  margin-top: 27px;
  color: #353535;
  font-family: ${({ theme }) => theme.fonts.interPrimary};
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;

  @media (max-width: 977px) {
    margin-top: 46px;
  }
`;

const Contacts = styled.div`
  &,
  * {
    color: #353535;
    font-family: ${({ theme }) => theme.fonts.interPrimary};
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  }

  p {
    margin: 0;

    span {
      // font-weight: 700;
    }
  }
`;
