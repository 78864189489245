import styled from "styled-components";

export const Container = styled.ul`
  width: fit-content;
  display: flex;
  gap: 64px;
  margin: 0 auto;
  position: sticky;
  top: 140px;
  z-index: 1;
  margin-top: -530px;
  padding-bottom: 510px;
`;

export const Tab = styled.li`
  font-family: Inter;
  font-weight: 600;
  font-size: 16px;
  display: block;
  color: #000;
  opacity: 0.2;
  ${({ $isActive }) =>
    $isActive
      ? `
    opacity: 1;
    color: #141414;
    font-weight: 800;
  `
      : `
  cursor: pointer;
  `}
`;
