import { useEffect } from "react";
import { HubspotInstallContent } from "../components";

const HubspotAppInstructions = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    return;
  }, []);
  return <HubspotInstallContent />;
};

export default HubspotAppInstructions;
