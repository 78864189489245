import React from "react";

import {
  Container,
  UserContainer,
  AvatarImage,
  Info,
  Name,
  JobTitle,
} from "./styles";

const UserList = ({ comments, commentInfo, onSelect }) => {
  const currentCommentIdx = comments.findIndex(
    ({ user: { name }, text }) =>
      name === commentInfo.user.name && text === commentInfo.text,
  );

  const onClick = (index) => {
    if (index === currentCommentIdx) {
      return;
    }

    onSelect(index);
  };

  return (
    <Container>
      {comments.map((comment, i) => (
        <UserContainer key={i}>
          <AvatarImage
            src={`images/comment-avatar-${comment.user.imageType}.png`}
            onClick={() => onClick(i)}
            $isCurrent={currentCommentIdx === i}
          />
          {currentCommentIdx === i && (
            <Info>
              <Name>{commentInfo.user.name}</Name>
              <JobTitle>{commentInfo.user.jobTitle}</JobTitle>
            </Info>
          )}
        </UserContainer>
      ))}
    </Container>
  );
};

export default UserList;
