import styled from "styled-components";

const ErrorPopup = ({ message, bgColor }) => {
  return <PopupContainer $bgColor={bgColor}>{message}</PopupContainer>;
};

export default ErrorPopup;

const PopupContainer = styled.div`
  position: fixed;
  top: 20px;
  left: calc((100vw - 300px) / 2);
  width: 300px;
  background-color: ${({ theme, $bgColor }) => $bgColor || theme.colors.milk};
  color: ${({ theme }) => theme.colors.blue};
  font-size: ${({ theme }) => theme.fontSizes.normal};
  text-align: center;
  padding: 20px;
  z-index: 9999;
  border-radius: ${({ theme }) => theme.borderRadius.normal};
  border: 1px solid ${({ theme }) => theme.colors.lightGrey};
`;
