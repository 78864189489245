import React from "react";
import { useNavigate } from "react-router-dom";
import {
  FooterContainer,
  FooterContent,
  License,
  LicenseContainer,
  Links,
  Year,
} from "./styles";

const Footer = ({ forPrivacyAndTerms }) => {
  const navigate = useNavigate();

  const fullYear = new Date().getFullYear();

  const goTo = (path) => {
    navigate(path);
  };

  return (
    <FooterContainer $forPrivacyAndTerms={forPrivacyAndTerms}>
      <FooterContent $forPrivacyAndTerms={forPrivacyAndTerms}>
        <LicenseContainer>
          <Year>{fullYear}</Year>
          <License>©SalesStream.ai</License>
        </LicenseContainer>
        <Links>
          <span onClick={() => goTo("/privacy")}>Privacy Policy</span>
          <span className="spt">|</span>
          <span onClick={() => goTo("/terms-conditions")}>Terms of Use</span>
        </Links>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;
