import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Wrapper } from "../HomeLanding/styles";
import Header from "../HomeLanding/Header";
import Footer from "../HomeLanding/Footer";
import {
  BackToHomeBtn,
  BackToHomeContainer,
  HeaderContainer,
  HeroContent,
  HeroLastUpd,
  HeroSection,
  HeroTitle,
  MainContent,
  MainSection,
} from "./styles";
import PageContent from "./PageContent";
import {
  PRIVACY_POLICY_CONTENT,
  TERMS_CONDITIONS_CONTENT,
} from "../../utils/constants";

const PrivacyAndTerms = ({ isTermsOfUse }) => {
  const title = isTermsOfUse
    ? "Terms of Use"
    : "Privacy, Cookies & Confidentiality";
  const content = isTermsOfUse
    ? TERMS_CONDITIONS_CONTENT
    : PRIVACY_POLICY_CONTENT;
  const lastUpdated = isTermsOfUse ? null : "Nov 1 2024";

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    return;
  }, [isTermsOfUse]);

  const handleClickBackToHome = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate("/");
  };

  return (
    <Wrapper>
      <BackToHomeContainer>
        <BackToHomeBtn onClick={handleClickBackToHome}>
          <img src="/images/back.svg" alt="back" />
          Back to home
        </BackToHomeBtn>
      </BackToHomeContainer>
      <HeaderContainer>
        <Header noTab />
      </HeaderContainer>
      <HeroSection>
        <HeroContent>
          <HeroTitle>{title}</HeroTitle>
          {!!lastUpdated && (
            <HeroLastUpd>
              Last updated on <span>{lastUpdated}</span>
            </HeroLastUpd>
          )}
        </HeroContent>
      </HeroSection>
      <MainSection>
        <MainContent>
          <PageContent content={content} />
        </MainContent>
      </MainSection>
      <Footer forPrivacyAndTerms />
    </Wrapper>
  );
};

export default PrivacyAndTerms;
