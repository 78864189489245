import React from "react";
import styled from "styled-components";
import { sanitizeHtml } from "../../utils/utils";

const TextBlock = ({ title, textArray }) => {
  return (
    <TextBlockContainer>
      <Title>{title}</Title>
      {textArray.map((text, index) => {
        const isListItem = text.includes("{middot}");
        const sanitizedText = sanitizeHtml(text.replace("{middot}", ""));
        return (
          <TextContent key={index} $isListItem={isListItem}>
            {isListItem && <DotIcon />}
            <p dangerouslySetInnerHTML={{ __html: sanitizedText }} />
          </TextContent>
        );
      })}
    </TextBlockContainer>
  );
};

export default TextBlock;

const TextBlockContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  margin: 0 0 17px;
  color: #141414;
  font-family: ${({ theme }) => theme.fonts.interPrimary};
  font-size: 30px;
  font-weight: 800;
  line-height: 30px;
  letter-spacing: -0.02em;

  @media (max-width: 767px) {
    margin: 0 0 16px;
    font-size: 28px;
    line-height: 28px;
  }
`;

const TextContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  column-gap: 9px;

  &,
  * {
    color: #353535;
    font-family: ${({ theme }) => theme.fonts.interPrimary};
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  }

  p {
    margin: 0;
  }

  & + & {
    margin-top: 21px;
  }

  ${({ $isListItem }) => ($isListItem ? "margin-top: 12px !important;" : "")};

  @media (max-width: 767px) {
    ${({ $isListItem }) => ($isListItem ? "margin-top: 10px !important;" : "")};
  }
`;

const DotIcon = styled.div`
  flex: 0 0 4px;
  width: 4px;
  height: 4px;
  margin-top: 8.5px;
  margin-left: 8.5px;
  background: #353535;
  border-radius: 50%;
`;
