import {
  OTHER_COMPANY,
  REACH_COMPANY,
  RECOMMENDATION_FEATURES,
} from "../../constants";

export const headerStickyTableData = ["Features", REACH_COMPANY.title];
export const headerScrollableTableData = [
  ...OTHER_COMPANY.map((firm) => firm.title),
];
export const getContentStickyTableData = () => {
  const data = [];

  for (const feature of RECOMMENDATION_FEATURES) {
    for (const [featureKey, featureValue] of Object.entries(feature)) {
      const row = [featureKey, REACH_COMPANY[featureValue]];
      data.push(row);
    }
  }

  return data;
};
export const getContentScrollableTableData = () => {
  const data = [];

  for (const feature of RECOMMENDATION_FEATURES) {
    for (const [, featureValue] of Object.entries(feature)) {
      const row = [];

      for (const firm of OTHER_COMPANY) {
        row.push(firm[featureValue]);
      }
      data.push(row);
    }
  }

  return data;
};
