import React, { useEffect, useRef, useState } from "react";
import { PlayButton, StyledVideo, VideoContainer } from "./styles";

const MainIntroVideo = ({ videoUrl, altVideoUrl, videoThumbnailSrc }) => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (videoRef.current?.paused) {
        videoRef.current.muted = true;
        videoRef.current.play();
        setIsPlaying(true);
      }
    }, 4000);

    return () => clearTimeout(timer);
  }, []);

  const togglePlay = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
        setIsPlaying(true);
      } else {
        videoRef.current.pause();
        setIsPlaying(false);
      }
      videoRef.current.muted = false;
    }
  };

  return (
    <VideoContainer>
      <StyledVideo ref={videoRef} onClick={togglePlay}>
        <source src={videoUrl} type="video/webm" />
        <source src={altVideoUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </StyledVideo>
      <PlayButton
        onClick={togglePlay}
        $isPlaying={isPlaying}
        $videoThumbnailSrc={videoThumbnailSrc}
      />
    </VideoContainer>
  );
};

export default MainIntroVideo;
