import styled from "styled-components";
import { sanitizeHtml as sanitize } from "../utils/utils";

const TextBlock = ({ title, textArray }) => {
    return (
        <TextBlockContainer>
            <Title>{title}</Title>
            <Text>
                {textArray.map((text, index) => {
                    // Sanitize the text to remove any potentially dangerous HTML
                    const sanitizedText = sanitize(text.replace("{middot}", "<img src='/images/middle-dot-icon.png' alt='list' />"));

                    return (
                        <TextContent key={index}>
                            <p dangerouslySetInnerHTML={{ __html: sanitizedText }}></p>
                        </TextContent>
                    );
                })}
            </Text>
        </TextBlockContainer>
    );
};

export default TextBlock;

const TextBlockContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  color: #4A4A4A;
  font-weight: 700;
  font-size: ${({ theme }) => theme.fontSizes.large};
  font-family: ${({ theme }) => theme.fonts.poppinsBold};
  margin: 20px 0;

  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.text};
    margin: 10px 0;
  }
`;

const Text = styled.div`
  color: #666666;
  font-weight: 400;
  font-size: ${({ theme }) => theme.fontSizes.small};
  line-height: 20px;

  p {
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.xSmall};

    p {
      margin-bottom: 5px;
    }
  }
`;

const TextContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  img[src*="middle-dot-icon"] {
    width: 7px;
    height: 6px;
    margin-right: 5px;
    margin-top: 7px;
  }
`;
