import { fetchValidateResetToken, fetchVerifyEmail } from "./queries.js";

const validateResetToken = async (token, isQaEnv) => {
  const result = await fetchValidateResetToken(token, isQaEnv);

  if (result.message) {
    return { success: false, message: result.message };
  }

  return { ...result, success: true };
};

const verifyEmail = async (data, isQaEnv) => {
  const result = await fetchVerifyEmail(data, isQaEnv);

  if (result.message) {
    return { success: false, message: result.message };
  }

  return { ...result, success: true };
};

export { validateResetToken, verifyEmail };
