import React, { forwardRef } from "react";

import { Item, Name } from "./styles";

const TabItem = forwardRef(({ name, isActive, onClick }, ref) => {
  return (
    <Item ref={ref} onClick={onClick}>
      <Name $isActive={isActive}>{name}</Name>
    </Item>
  );
});

export default TabItem;
