import styled from "styled-components";

export const Container = styled.div`
  margin-top: 72px;
  display: flex;
  justify-content: center;
  gap: 10px;

  @media (max-width: 767px) {
    margin-top: 39px;
  }
`;

export const UserContainer = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    width: 48px;
    gap: 0;
  }
`;

export const AvatarImage = styled.img`
  height: 48px;
  width: 48px;

  opacity: ${({ $isCurrent }) => ($isCurrent ? 1 : 0.3)};
  cursor: ${({ $isCurrent }) => ($isCurrent ? "default" : "pointer")};
`;

export const Info = styled.div`
  @media (max-width: 767px) {
    display: flex;
    align-items: center;
    flex-direction: column;
    align-items: center;
    width: 48px;
    align-items: center;
    width: fit-content;
  }
`;

export const Name = styled.p`
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 26.1px;
  text-align: center;
  white-space: nowrap;
  margin: 0;
`;

export const JobTitle = styled.span`
  font-family: Inter;
  font-size: 11.56px;
  font-weight: 400;
  line-height: 16.76px;
  text-align: left;
  white-space: nowrap;
  display: block;
`;
