import styled, { css } from "styled-components";

export const Container = styled.div`
  height: fit-content;
  width: fit-content;
  max-width: 100%;
  position: relative;
  padding: 0 60px;
  margin: 0 10px;

  img {
    width: 100%;
  }

  *,
  *::before,
  *::after {
    animation-play-state: ${(props) =>
      props.$isStarted ? "running" : "paused"};
  }

  @media (max-width: 767px) {
    padding-left: 30px;
  }
`;

export const MessagesArea = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  animation-name: hide;
  animation-iteration-count: 1;
  animation-timing-function: linear(0 0%, 0 83%, 1 100%);
  animation-duration: 3s;
  opacity: 0;

  @keyframes hide {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

const calcWithPadding = (padding, percents) =>
  `calc((100% - ${padding * 2}px) * ${percents / 100} + ${padding}px)`;

export const DottedMessage = styled.div`
  position: absolute;
  transform: translateY(-100%);
  top: ${(props) => `${props.$top}%`};
  left: ${(props) =>
    props.$left ? calcWithPadding(60, props.$left) : "unset"};
  right: ${(props) =>
    props.$right ? calcWithPadding(60, props.$right) : "unset"};
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.$right ? "flex-end" : "flex-start")};
  animation-name: appear;
  animation-iteration-count: 1;

  @media (max-width: 767px) {
    left: ${(props) =>
      props.$left ? calcWithPadding(0, props.$left) : "unset"};
    right: ${(props) =>
      props.$right ? calcWithPadding(0, props.$right) : "unset"};
  }

  &:nth-child(1) {
    animation-timing-function: linear(0 0%, 0 25%, 1 50%);
    animation-duration: 2s;
  }

  &:nth-child(2) {
    animation-timing-function: linear(0 0%, 0 50%, 1 75%);
    animation-duration: 2s;
  }

  &:nth-child(3) {
    animation-timing-function: linear(0 0%, 1 25%);
    animation-duration: 2s;
  }

  &::after {
    content: "";
    display: block;
    height: 6px;
    width: 6px;
    border: 3px solid #dbc7e7;
    border-radius: 50%;
    background-color: #996db4;
    margin-top: 0px;
    ${(props) =>
      props.$right
        ? css`
            margin-right: -13px;
          `
        : css`
            margin-left: -13px;
          `}
  }

  @keyframes appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
