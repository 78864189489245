import styled from "styled-components";

const PrimaryButton = ({
  label,
  isInjectedButton = false,
  isSmall = false,
  isMedium = false,
  isBordered,
  margin,
  onClick,
}) => {
  return (
    <Button
      $isInjectedButton={isInjectedButton}
      $isBordered={isBordered}
      $isSmall={isSmall}
      $isMedium={isMedium}
      $margin={margin}
      onClick={onClick}
      type="button"
    >
      {label}
    </Button>
  );
};

export default PrimaryButton;

const Button = styled.button`
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.purple};
  color: ${({ theme }) => theme.colors.white};
  font-weight: 800;
  font-size: ${({ $isSmall, $isMedium, theme }) =>
    $isSmall || $isMedium ? theme.fontSizes.small : theme.fontSizes.button};
  border-radius: ${({ $isInjectedButton, $isMedium, theme }) =>
    $isInjectedButton || $isMedium
      ? theme.borderRadius.injectedButton
      : theme.borderRadius.button};
  border: none;
  padding: 0 23px 0 23px;
  height: ${({ $isSmall, $isMedium }) =>
    $isSmall ? "35px" : $isMedium ? "46px" : "44px"};
  ${({ $margin }) => $margin && "margin: " + $margin + ";"}
  ${({ $isInjectedButton }) => $isInjectedButton && "position: absolute;"}
  ${({ $isInjectedButton }) => $isInjectedButton && "top: 0;"}
  ${({ $isInjectedButton }) => $isInjectedButton && "right: 0;"}
  ${({ $isInjectedButton, $isBordered }) =>
    ($isInjectedButton && $isBordered && "margin: 2px;") ||
    ($isInjectedButton && !$isBordered && "margin: 3px;")}
`;
