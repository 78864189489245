import React, { useRef, useState, useEffect } from "react";

import TabItem from "./TabItem";
import { NAVIGATION_TAB_LIST } from "../../constants";

import { ActiveArrow, List } from "./styles";

function TabsList({ activeTab, handleTabClick }) {
  const tabRefs = useRef(
    Object.fromEntries(NAVIGATION_TAB_LIST.map((tab) => [tab.key, null])),
  );
  const [arrowStyle, setArrowStyle] = useState({ left: 0 });

  useEffect(() => {
    const { offsetLeft = 0, offsetWidth = 0 } =
      tabRefs.current[activeTab?.key] || {};
    const calculatedLeft = offsetLeft + offsetWidth / 2;

    setArrowStyle((style) => ({
      left: calculatedLeft,
      opacity: 1,
      transition: style.left === 0 ? "none" : "all 0.3s",
    }));
  }, [activeTab]);

  return (
    <>
      <List>
        <ActiveArrow style={arrowStyle} />
        {NAVIGATION_TAB_LIST.map((tab) => (
          <TabItem
            key={tab.id}
            ref={(el) => (tabRefs.current[tab.key] = el)}
            isActive={activeTab?.id === tab.id}
            onClick={() => handleTabClick(tab)}
            name={tab.name}
          />
        ))}
      </List>
    </>
  );
}

export default TabsList;
