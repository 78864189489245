import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;
`;

export const Title = styled.p`
  text-align: center;
  font-family: "Inter";
  font-size: 36px;
  font-weight: 800;
  line-height: 36px;
  color: #141414;

  @media (max-width: 768px) {
    font-size: 28px;
    line-height: 28px;
  }
`;

export const Description = styled.p`
  text-align: center;
  font-family: "Inter";
  font-size: 21px;
  font-weight: 400;
  line-height: 31.71px;
  color: #353535;
  max-width: 709px;
  margin-top: 15px;

  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 27.18px;
    margin-top: 18px;
  }
`;

export const TablesContainer = styled.div`
  width: 100%;
  margin-top: 93px;
  display: flex;

  @media (max-width: 768px) {
    margin-top: 47px;
  }
`;
