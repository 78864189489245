import {
  Header,
  SmallMainTitle,
  TextBlock,
  ContactsInfoCard,
  JoinToWaitList,
  Footer,
  ErrorPopup,
} from "../components";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { useState } from "react";
import { addToWaitList } from "../utils/utils";
import {
  CONTACT_EMAIL,
  ADDRESS,
  COMPANY_NAME,
  HUBSPOT_INSTALL_CONTENT,
} from "../utils/constants";

const HubspotInstallContent = () => {
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  const [recordsCount, setRecordsCount] = useState(null);

  const isMobile = useMediaQuery({ maxWidth: 768 });

  const goTo = (id) => {
    if (!id) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      return;
    }
    const section = document.getElementById(id);
    section.scrollIntoView({ behavior: "smooth" });
  };

  const joinToWaitList = async (value) => {
    const response = await addToWaitList(value, true);

    if (!response) {
      return;
    }

    if (typeof response === "string") {
      setPopupMessage(response);
      setIsShowPopup(true);
      setTimeout(() => {
        setPopupMessage("");
        setIsShowPopup(false);
      }, 2000);
      return;
    }

    if (!response.success) {
      setPopupMessage(response.message);
      setIsShowPopup(true);
      setTimeout(() => {
        setPopupMessage("");
        setIsShowPopup(false);
      }, 2000);
      return;
    }

    setRecordsCount(response.recordsCount);
    goTo();
  };

  return (
    <PrivacyPageContainer>
      <Header
        isMobile={isMobile}
        recordsCount={recordsCount}
        goToJoin={() => goTo("enter-email-to-join")}
      />
      <SmallMainTitle title="HubSpot Setup Instructions" />
      <Content>
        <TextSection>
          {HUBSPOT_INSTALL_CONTENT.map((item, index) => (
            <TextBlock
              key={index}
              title={item.title}
              textArray={item.textArray}
            />
          ))}
          <ContactsTitle>{COMPANY_NAME}</ContactsTitle>
          <Contacts>
            {ADDRESS.map((item, index) => (
              <p key={index}>{item}</p>
            ))}

            <p>
              <span>E-mail</span> {CONTACT_EMAIL}
            </p>
          </Contacts>
        </TextSection>
        {!isMobile && (
          <InfoSection>
            <ContactsInfoCard />
          </InfoSection>
        )}
      </Content>
      <JoinToWaitList
        isMobile={isMobile}
        joinToWaitList={joinToWaitList}
      ></JoinToWaitList>
      <Footer></Footer>
      {isShowPopup && <ErrorPopup message={popupMessage}></ErrorPopup>}
    </PrivacyPageContainer>
  );
};

export default HubspotInstallContent;

const PrivacyPageContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.pageGrey};
  * {
    font-family: ${({ theme }) => theme.fonts.poppinsPrimary};
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
`;

const TextSection = styled.div`
  padding: 2vw 5vw;
  width: 60vw;

  @media (max-width: 768px) {
    width: 100vw;
  }
`;

const InfoSection = styled.div`
  padding-right: 5vw;
`;

const ContactsTitle = styled.div`
  font-family: ${({ theme }) => theme.fonts.poppinsBold};
  font-size: ${({ theme }) => theme.fontSizes.xMedium};
  font-weight: 700;
  color: #4a4a4a;
  margin-top: 20px;
`;

const Contacts = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: 400;
  color: #666666;

  p {
    margin: 4px 0;

    span {
      font-family: ${({ theme }) => theme.fonts.poppinsBold};
      font-weight: 700;
    }
  }
`;
