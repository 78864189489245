import React from "react";

import { Container, Text } from "./styles";

const Message = ({ text, isOwn, isBigText }) => {
  return (
    <Container $isOwn={isOwn}>
      <Text $isBigText={isBigText} dangerouslySetInnerHTML={{ __html: text }} />
    </Container>
  );
};

export default Message;
