import React from "react";

import { Container, MessagesArea, DottedMessage } from "./styles";
import Message from "../Message";

const SecondAnimation = ({ isStarted }) => {
  const messages = [
    {
      text: "Hi Kim! It was great to see you at AIFest last week! Are you still open to getting a cup of coffee?",
      top: 28,
      left: 41,
      isOwn: false,
    },
    {
      text: "What an amazing party last week! Glad we got to catch up. What’s a good time for quick call to discuss your new project?",
      top: 73.5,
      right: 63,
      isOwn: true,
    },
    {
      text: "Sean - Congrats on the new role! I’d love to touch base. Are you still planning to attend CES this year?",
      top: 87.8,
      left: 49,
      isOwn: false,
    },
  ];

  return (
    <Container $isStarted={isStarted}>
      <img src="images/feat-2.png" alt="feature" />
      <MessagesArea>
        {messages.map(({ text, top, left, right, isOwn }, i) => (
          <DottedMessage key={i} $top={top} $left={left} $right={right}>
            <Message text={text} isOwn={isOwn} />
          </DottedMessage>
        ))}
      </MessagesArea>
    </Container>
  );
};

export default SecondAnimation;
