import React from "react";

import {
  ConfirmPopupContainer,
  Image,
  Overlay,
  PopupDescription,
  PopupTitle,
} from "./styles";

function SuggestionPopup({
  imageSrc = "/images/lamp.png",
  imageAlt = "lamp",
  title,
  description,
  styles,
  isNoOverlay = false,
}) {
  const { imageStyles, titleStyles } = styles || {};

  return (
    <>
      <ConfirmPopupContainer>
        <Image $imageStyles={imageStyles} src={imageSrc} alt={imageAlt} />
        <PopupTitle $titleStyles={titleStyles}>{title}</PopupTitle>
        <PopupDescription>{description}</PopupDescription>
      </ConfirmPopupContainer>
      {!isNoOverlay && <Overlay />}
    </>
  );
}

export default SuggestionPopup;
