import React from "react";

import Sticky from "./Sticky";
import Scrollable from "./Scrollable";
import {
  headerStickyTableData,
  headerScrollableTableData,
  getContentStickyTableData,
  getContentScrollableTableData,
} from "./utils";

import { ScrollableTableContainer, StickyTableContainer } from "./styles";

function Tables() {
  const rowDataStickyTable = getContentStickyTableData();
  const rowDataScrollableTable = getContentScrollableTableData();

  return (
    <>
      <StickyTableContainer>
        <Sticky
          headerData={headerStickyTableData}
          contentData={rowDataStickyTable}
        />
      </StickyTableContainer>
      <ScrollableTableContainer>
        <Scrollable
          headerData={headerScrollableTableData}
          contentData={rowDataScrollableTable}
        />
      </ScrollableTableContainer>
    </>
  );
}

export default Tables;
