import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding-bottom: 27px;
  padding-right: 66px;

  *,
  *::before,
  *::after {
    animation-play-state: ${(props) =>
      props.$isStarted ? "running" : "paused"};
  }

  @media (min-width: 768px) and (max-width: 1260px) {
    height: 360px;
    padding-right: 20px;
  }

  @media (max-width: 767px) {
    height: 460px;
    padding-right: 0;
  }

  @media (max-width: 576px) {
    height: 360px;
    padding-right: 0;
  }
`;

export const Ellipse = styled.div`
  width: 100%;
  max-width: 493px;
  height: 132.08px;
  background-color: #7a93e2;
  border-radius: 50%;
  align-self: flex-end;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    max-width: 493px;
    z-index: 1;
    bottom: -39px;
    height: 151px;
    background-color: transparent;
    border: 40px solid #e0c2f2;
    border-right-color: transparent;
    border-radius: 50%;
    border-left-color: transparent;
    border-top: 0;
    box-sizing: border-box;

    @media (max-width: 576px) {
      border-width: 41px;
      bottom: -41px;
      height: 137px;
    }

    @media (min-width: 768px) and (max-width: 1260px) {
      border-width: 41px;
      bottom: -41px;
      height: 137px;
    }
  }
`;

export const LogosContainer = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);
  top: 234px;
  left: 50%;
  width: 194px;
  height: 194px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 576px) {
    transform: scale(0.6);
    width: 97px;
    height: 97px;
    top: 145px;
    left: calc(50% - 97px / 2);
  }

  @media (min-width: 768px) and (max-width: 1260px) {
    transform: scale(0.6);
    width: 97px;
    height: 97px;
    top: 145px;
    left: calc(50% - 97px / 2);
  }
`;

export const LogoImage = styled.img`
  position: absolute;
  transform-origin: center;

  @media (max-width: 767px) {
    transform: scale(0.5);
  }
`;

export const ReachLogoImage = styled(LogoImage)`
  z-index: 3;
  animation-name: moveToTopReach;
  animation-duration: 0.5s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
  animation-delay: 0s;

  @keyframes moveToTopReach {
    from {
      transform: translate(0, 0);
    }
    to {
      transform: translate(-150px, -330px);
    }
  }

  @media (max-width: 576px) {
    @keyframes moveToTopReach {
      from {
        transform: translate(0, 0);
      }
      to {
        transform: translate(-150px, -470px);
      }
    }
  }

  @media (min-width: 768px) and (max-width: 1260px) {
    @keyframes moveToTopReach {
      from {
        transform: translate(0, 0);
      }
      to {
        transform: translate(-150px, -470px);
      }
    }
  }
`;

export const HubspotLogoImage = styled(LogoImage)`
  z-index: 2;
  animation-name: moveToTopHubspot;
  animation-duration: 0.5s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;

  @keyframes moveToTopHubspot {
    from {
      transform: translate(0, 0);
    }
    to {
      transform: translate(-54px, -408px);
    }
  }

  @media (max-width: 576px) {
    @keyframes moveToTopHubspot {
      from {
        transform: translate(0, 0);
      }
      to {
        transform: translate(-54px, -535px);
      }
    }
  }

  @media (min-width: 768px) and (max-width: 1260px) {
    @keyframes moveToTopHubspot {
      from {
        transform: translate(0, 0);
      }
      to {
        transform: translate(-54px, -535px);
      }
    }
  }
`;

export const BullhornLogoImage = styled(LogoImage)`
  z-index: 1;
  animation-name: moveToTopBullhorn;
  animation-duration: 0.5s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
  animation-delay: 1s;

  @keyframes moveToTopBullhorn {
    from {
      transform: translate(0, 0);
    }
    to {
      transform: translate(98px, -395px);
    }
  }

  @media (max-width: 576px) {
    @keyframes moveToTopBullhorn {
      from {
        transform: translate(0, 0);
      }
      to {
        transform: translate(98px, -518px);
      }
    }
  }

  @media (min-width: 768px) and (max-width: 1260px) {
    @keyframes moveToTopBullhorn {
      from {
        transform: translate(0, 0);
      }
      to {
        transform: translate(98px, -518px);
      }
    }
  }
`;
