import styled from "styled-components";
import Logo from "./Logo";
import AddedToWaitListMessage from "./AddedToWaitListMessage";
import PrimaryButton from "./PrimaryButton";
import { useNavigate } from "react-router-dom";

const Header = ({ isMobile, recordsCount, goToJoin }) => {
  const navigate = useNavigate();

  return (
    <HeaderContainer $isMobile={isMobile}>
      <Logo
        isClickable={true}
        onClick={() => navigate("/")}
        color="pink"
        width={isMobile ? "101" : "132"}
      />
      {!isMobile && recordsCount ? (
        <ContentBlock>
          <AddedToWaitListMessage
            recordsCount={recordsCount}
            color="purple"
            smallSize={true}
          />
        </ContentBlock>
      ) : (
        !isMobile && (
          <ContentBlock>
            <PrimaryButton
              label="Start Your Free Trial"
              onClick={goToJoin}
            ></PrimaryButton>
          </ContentBlock>
        )
      )}
    </HeaderContainer>
  );
};

export default Header;

const HeaderContainer = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.white};
  height: ${({ $isMobile }) => ($isMobile ? "55px" : "70px")};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 5vw;
  z-index: 999;
`;

const ContentBlock = styled.div`
  border-left: 1px solid ${({ theme }) => theme.colors.border};
  height: 100%;
  display: flex;
  padding-left: 30px;
  align-items: center;
`;
