import React from "react";
import EmailInput from "./EmailInput";
import { Description, Logo, SubscribeContainer, Title } from "./styles";

const Subscribe = () => {
  return (
    <SubscribeContainer id="enter-email-to-join">
      <Logo />
      <Title>Subscribe to Reach</Title>
      <Description>
          Join our mailing list to stay updated with the latest news and updates from Reach.
      </Description>
      <EmailInput />
    </SubscribeContainer>
  );
};

export default Subscribe;
