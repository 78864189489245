import styled from "styled-components";

export const List = styled.ul`
  display: flex;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-right: 81.5px;
  height: 100%;
  position: relative;
  padding-left: 14px;
  padding-right: 25px;

  @media (max-width: 977px) {
    display: none;
  }
`;

export const ActiveArrow = styled.div`
  background-image: url("/images/active-nav-icon.svg");
  display: block;
  position: absolute;
  top: 0px;
  left: 50%;
  width: 26px;
  height: 9px;
  transition: all 0.3s;
  transform: translateX(-50%);
  opacity: 0;
`;
