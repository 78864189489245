import styled from "styled-components";

export const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
`;

export const StyledVideo = styled.video`
  width: 100%;
  height: auto;
  border-radius: ${({ theme }) => theme.borderRadius.normal};
`;

export const PlayButton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  border-radius: ${({ theme }) => theme.borderRadius.normal};
  height: 100%; // Circle height as 30% of video height
  width: 100%; // Circle width as 30% of video height
  display: ${({ $isPlaying }) => ($isPlaying ? "none" : "flex")};
  background-image: url(${({ $videoThumbnailSrc }) => $videoThumbnailSrc});
  background-repeat: no-repeat;
  background-position: center;
  background-size: ${({ $videoThumbnailSrc }) =>
    $videoThumbnailSrc ? "100%" : "0%"};
`;
