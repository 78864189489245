import React from "react";
import { useMediaQuery } from "react-responsive";

import {
  LEGAL_BENEFITS_DESCRIPTION,
  getLegalBenefits,
} from "../utils/constants";
import { BenefitsDescription } from "../components";
import HubspotBaseLanding from "./HubspotBaseLanding";

function HubspotForLegal() {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const benefitsList = getLegalBenefits(isMobile);
  const { part1, part2 } = LEGAL_BENEFITS_DESCRIPTION;

  return (
    <HubspotBaseLanding
      descriptionContent={() => (
        <BenefitsDescription part1={part1} part2={part2} />
      )}
      benefitsList={benefitsList}
    />
  );
}

export default HubspotForLegal;
