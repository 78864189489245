import styled from "styled-components";

const BenefitCard = ({ benefit }) => {
  return (
    <BenefitCardContainer $width={benefit.width}>
      <IconBlock>
        <img src={benefit.icon} alt="benefit icon" width={benefit.iconWidth} />
      </IconBlock>
      <Title>{benefit.title}</Title>
      <Content>
        <span>{benefit.content}</span>
      </Content>
    </BenefitCardContainer>
  );
};

export default BenefitCard;

const BenefitCardContainer = styled.div`
  max-width: ${({ $width }) => $width};
  display: flex;
  flex-direction: column;
  margin-top: 4vw;

  @media (max-width: 768px) {
    align-items: center;
    max-width: 70vw;
  }
`;

const IconBlock = styled.div`
  height: 60px;
`;

const Title = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.text};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-weight: 800;
  margin: 10px 0;
  line-height: 21.15px;
  letter-spacing: -0.03em;

  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.normal};
    text-align: center;
  }
`;

const Content = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: 400;
  line-height: 21px;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.colors.blue};

  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.small};
    text-align: center;
  }
`;
