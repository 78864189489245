import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import TabsList from "./TabList";
import { NAVIGATION_TAB_LIST } from "../constants";
import { CHROME_STORE_EXTENSION_URL } from "../../../utils/constants";

import {
  Container,
  Logo,
  LogoContainer,
  TabsContainer,
  TryLink,
} from "./styles";

function Header({ noTab = false, sectionRefs }) {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState();

  const handleTabClick = (tab) => {
    if (noTab || !sectionRefs) {
      return;
    }

    const section = sectionRefs.current?.[tab.key];
    if (!section) {
      return;
    }

    section.scrollIntoView({ behavior: "smooth" });
  };

  const findActiveTab = () => {
    if (noTab || !sectionRefs) {
      return;
    }

    Object.values(sectionRefs.current).forEach((section, index) => {
      const rect = section.getBoundingClientRect();
      if (rect.top <= window.innerHeight / 2) {
        setActiveTab(NAVIGATION_TAB_LIST[index]);
      }
    });
  };

  useEffect(() => {
    if (noTab || !sectionRefs) {
      return;
    }

    findActiveTab();
    window.addEventListener("scroll", findActiveTab);
    return () => {
      window.removeEventListener("scroll", findActiveTab);
    };
  }, []);

  return (
    <Container>
      <LogoContainer>
        <Logo
          src="/images/full-logo-pink.png"
          alt="Logo"
          onClick={() => navigate("/")}
        />
      </LogoContainer>
      <TabsContainer $noTab={noTab}>
        {!noTab && (
          <TabsList activeTab={activeTab} handleTabClick={handleTabClick} />
        )}
        <TryLink
          href={CHROME_STORE_EXTENSION_URL}
          target="_blank"
          $noTab={noTab}
        >
          Try Reach
        </TryLink>
      </TabsContainer>
    </Container>
  );
}

export default Header;
