import React from "react";
import { useMediaQuery } from "react-responsive";

import {
  FINANCE_BENEFITS_DESCRIPTION,
  getFinanceBenefits,
} from "../utils/constants";
import { BenefitsDescription } from "../components";
import HubspotBaseLanding from "./HubspotBaseLanding";

function HubspotForFinance() {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const benefitsList = getFinanceBenefits(isMobile);
  const { part1, part2 } = FINANCE_BENEFITS_DESCRIPTION;

  return (
    <HubspotBaseLanding
      descriptionContent={() => (
        <BenefitsDescription part1={part1} part2={part2} />
      )}
      benefitsList={benefitsList}
    />
  );
}

export default HubspotForFinance;
